<template>
  <b-card  class="text-center">
    <b-card-header>
      <b-card-title class="text-center"><span class="text-info">Availble Ports</span></b-card-title>
    </b-card-header>
    <div v-if="loading" class="d-flex justify-content-center mb-1">
      <b-spinner label="Loading..." />
    </div>

    <b-row>
      <b-col md="8">
        <div class="d-flex mb-2">
          <b-button 
            variant="success"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-1"
            @click="onSelectAll"
            >SELECT ALL</b-button>
          <b-button 
            variant="success"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-1"
            @click="onChangeProxy"
            >SAVE SETTINGS</b-button>
          <b-button 
            variant="success"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-1"
            @click="onChangeIP"
            >CHANGES NEW IP</b-button>
          <b-button 
            variant="info"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-1"
            @click="setAuto(true)"
            >ON AUTO RENEW</b-button>
          <b-button 
            variant="info"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-1"
            @click="setAuto(false)"
            >OFF AUTO RENEW</b-button>
          <b-button 
            variant="warning"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-1"
            @click="setRenew(false)"
            >RENEW NOW</b-button>
          <b-button 
            variant="warning"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-1"
            @click="onExport"
            >EXPORT</b-button>
        </div>
      </b-col>
      <b-col md="4">
        <div class="d-flex mb-2">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    

    <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        ref="table"
      >
        <template #cell(selected)="data">
          <b-form-group>
              <b-form-checkbox
                class="mr-0 mt-50"
                name="is-rtl"
                inline
                v-model="data.item.selected"
              />
          </b-form-group>
        </template>
        <template #cell(geo)="data">
          <v-select
            :options="geos"
            v-model="data.item.geo"
          />
        </template>
        <template #cell(rotate)="data">
          <b-form-spinbutton
            v-model="data.item.rotate"
            min="3"
            max="15"
          />
        </template>
        <template #cell(white_ip)="data">
          <b-form-input
              id="filterInput"
              type="search"
              v-model="data.item.white_ip"
              placeholder="White IP"
            />
        </template>
        <template #cell(renew)="data">
          <b-badge v-if="data.item.renew" variant="success">On</b-badge>
          <b-badge v-else variant="danger">Off</b-badge>
        </template>
        <template #cell(expiring)="data">
          <b-badge :variant="diffDate(data.item.expiring).alert">
            {{diffDate(data.item.expiring).diffDays}}
          </b-badge>
        </template>
        <!-- <template #cell(_id)=data>
          <div class="text-nowrap">
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                :variant="data.item.status ? 'flat-danger':'flat-success'"
                class="btn-icon rounded-circle"
                @click="activeAccount(data.item._id)"
              >
                <feather-icon :icon="data.item.status ? 'LockIcon' : 'UnlockIcon'" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-warning"
                class="btn-icon rounded-circle"
                @click="showEditModal(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="deleteAccount(data.item._id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
          </div>
          <div class="text-nowrap">
          
          </div>
        </template> -->
    </b-table>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    <b-modal
      id="modal-renew"
      ok-title="Renew"
      cancel-title="Cancel"
      size="lg"
      :title="'[Renew] '+select_item.length + ' ports'"
      @ok="onSubmit"
    >
      <div class="text-center">
        <h4>
          Select Day for Renew
        </h4>
        <vue-slider
          v-model="select_day" :min="1" :max="100"
        />
        <h4 class="mt-3 center">
          Renew {{select_item.length}} ports of {{select_day}} days for {{  Number(select_item.length*select_day*0.934).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}
        </h4>
      </div>
    </b-modal>
    </b-card>
    
   
</template>

<script>
import {
 BRow,BCol, BCard, BCardHeader, BCardTitle, BBadge, BButton,BCardFooter,BCardText, BCardBody,BTable, BSpinner, BFormSelect,
 BPagination,BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormSpinbutton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Swiper, SwiperSlide , } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import vSelect from 'vue-select'
import RepositoryFactory from '../../../api/RepositoryFactory'
const PlansRepository = RepositoryFactory.get('plans')
import VueSlider from 'vue-slider-component'

export default {
  components: {
    BRow,BCol, BCard,BCardHeader,BCardTitle,BBadge,BButton,BCardFooter,BCardText,BCardBody, BTable,BFormSelect,
    Swiper,
    SwiperSlide,
    BSpinner,
    BPagination,BFormCheckbox, BFormGroup, BFormInput, BFormSelect, vSelect, BInputGroup, BInputGroupAppend, VueSlider,BFormSpinbutton
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      plan: null,
      plansData:[],
      loading: false,
      perPage: 20,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      userList: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: "stt", label: "#", sortable: false },
        { key: "selected", label: "Select", sortable: false },
        { key: 'expiring', label: 'Expiring', sortable: true },
        { key: 'renew', label: 'Auto Renew', sortable: true },
        { key: 'port', label: 'Proxy', sortable: true },
        //{ key: 'current_ip', label: 'Current IP', sortable: true },
        { key: 'geo', label: 'GEO', sortable: true },
        { key: 'rotate', label: 'ROTATE TIME', sortable: true },
        { key: 'white_ip', label: 'White_IP', sortable: true },
        // { key: '_id', label: 'Action' },
      ],
      select_day: 30,
      // geos: [
      //   { value: 'US' , label: 'United States' },
      //   { value: 'GB', label: 'United Kingdom'},
      //   { value: 'AU', label:'Australia'},
      //   { value: 'CA', label: 'Canada'},
      //   {value : 'FR', label: 'France'},
      //   {value : 'DE', label: 'Germany'},
      //   {value : 'AX', label: 'Åland Islands'},
      //   {value : 'DZ', label: 'Algeria'},
      //   {value : 'AS', label: 'American Samoa'},
      //   {value : 'AD', label: 'Andorra'},
      //   {value : 'AO', label: 'Angola'},
      // ],
      geos: [
        'US',
        'GB',
        'AU',
        'CA',
        'FR',
        'DE',
        'AX',
        'DZ', 
        'AS',
        'AD', 
        'AO',
      ],
      /* eslint-disable global-require */
      items:[],
      select_item: []
    }
  },
  created(){
    this.loading = true
    PlansRepository.fetch().then(rs=>{
      this.loading = false
      this.items = rs.data.data.sort((a, b) => (a.buy_at < b.buy_at) ? -1 : ((a.buy_at > b.buy_at) ? 1 : 0))
      let i = 1;
      this.items.forEach(x=>{
        x.stt = i
        x.selected = false
        //x.geo = this.
        i++

      })
      this.totalRows = this.items.length
      this.$parent.updatePlan(this.items.length)
      let countEx = this.items.filter(x=>{
        if(this.getDayEx(x.expiring))
          return x
      })
      this.$parent.updatePlanEx(countEx.length)
      this.$parent.updateRenew(this.items)
      this.geos = this.$store.getters['auth/settings'].geos_avail
    })
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods:{
    onChangeProxy(){
      let arr = this.items.filter(x=>x.selected).map((data)=>{
        return {
          _id: data._id,
          geo: data.geo,
          port: data.port,
          rotate: data.rotate,
          white_ip: data.white_ip
        }
      })

      if(arr.length <= 0)
      {
        this.$bvToast.toast('Please select at least 1', {
          title: 'Error',
          variant: 'danger',
          solid: false,
        })
        return
      }

      PlansRepository.change(arr).then(x=>{
        if(x.data.success){
          this.$bvToast.toast('Change success', {
            title: 'Success',
            variant: 'success',
            solid: false,
          })
        }else{
          this.$bvToast.toast('Error: '+x.data.errMsg, {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
        }
        
      })
    },
    onChangeIP(){
      let arr = this.items.filter(x=>x.selected).map((data)=>{
        return {
          _id: data._id,
          port: data.port,
        }
      })

      if(arr.length <= 0)
      {
        this.$bvToast.toast('Please select at least 1', {
          title: 'Error',
          variant: 'danger',
          solid: false,
        })
        return
      }

      PlansRepository.changeIP(arr).then(x=>{
        if(x.data.success){
          this.$bvToast.toast('Change success', {
            title: 'Success',
            variant: 'success',
            solid: false,
          })
        }else{
          this.$bvToast.toast('Error: '+x.data.errMsg, {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
        }
        
      })
    },
    setAuto(select){
      let arr = this.items.filter(x=>x.selected).map(x=>x._id)
      if(arr.length <= 0)
      {
        this.$bvToast.toast('Please select at least 1', {
          title: 'Error',
          variant: 'danger',
          solid: false,
        })
        return
      }

      PlansRepository.setAuto(select,arr).then(x=>{

        this.items.forEach(x=>{
          if(x.selected){
            x.renew = select
          }
        })
        if(x.data.success){
          this.$bvToast.toast('Change success', {
            title: 'Success',
            variant: 'success',
            solid: false,
          })
        }else{
          this.$bvToast.toast('Error: '+x.data.errMsg, {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
        }
      })
      
    },
    setRenew(){
      let arr = this.items.filter(x=>x.selected).map(x=>x._id)
      if(arr.length <= 0)
      {
        this.$bvToast.toast('Please select at least 1', {
          title: 'Error',
          variant: 'danger',
          solid: false,
        })
        return
      }
      this.select_item = arr
      this.select_day = 30
      this.$bvModal.show('modal-renew')
    },
    onSubmit(event){
      event.preventDefault()
      let arr = this.items.filter(x=>x.selected).map(x=>x._id)
      PlansRepository.renew(arr,this.select_day,arr.length).then(rs=>{
        if(rs.data.success){
          this.$bvToast.toast('Renew success', {
            title: 'Success',
            variant: 'success',
            solid: false,
          })
          this.$bvModal.hide('modal-renew')
          this.items.filter(x=>x.selected).forEach(x=>{
            x.expiring = this.addDays(x.expiring,this.select_day)
          })
        }else{
          this.$bvToast.toast('Error: '+rs.data.errMsg, {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
        }
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onSelectAll(){
      this.items.forEach(x=>{
        x.selected = true
      })
      this.$refs.table.refresh()
    },
    onExport(){
      let arr = this.items.filter(x=>x.selected).map(x=>x.port)
      let s = arr.join('\n')
      this.download('ports.txt',s)
    },
    download(filename,text){
    // Set up the link
        var link = document.createElement("a");
        link.setAttribute("target","_blank");
        if(Blob !== undefined) {
            var blob = new Blob([text], {type: "text/plain"});
            link.setAttribute("href", URL.createObjectURL(blob));
        } else {
            link.setAttribute("href","data:text/plain," + encodeURIComponent(text));
        }
        link.setAttribute("download",filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    // diffDate(d){
    //   var diffDays = parseInt((new Date(d) - Date.now()) / (1000 * 60 * 60 * 24), 10); 
    //   let alert = "";
    //   if(diffDays > 3)
    //     alert = "light-success"
    //   else
    //     alert = "light-danger"

    //   if(diffDays < 1){
    //      diffDays = parseInt((new Date(d) - Date.now()) / (1000 * 60 * 60 * 24), 10); 
    //   }
    //   return {
    //     diffDays,
    //     alert
    //   }
    // },
    diffDate(startDate) {
      let delta = Math.abs(new Date(startDate) - Date.now()) / 1000;
      const isNegative = new Date(startDate) < Date.now() ? -1 : 1;
      let data = [
        ['days', 24 * 60 * 60],
        ['hours', 60 * 60],
        ['minutes', 60],
        ['seconds', 1]
      ].reduce((acc, [key, value]) => (acc[key] = Math.floor(delta / value) * isNegative, delta -= acc[key] * isNegative * value, acc), {});

      let alert = data.days < 1 ? 'light-danger' : 'light-success'
      let diffDays = ""
      if(data.days > 1 || data.hours > 1){
        diffDays = `${data.days} days ${data.hours} hours` 
      }else if(data.minutes > 0){
        diffDays = `${data.minutes} minutes`
      }else{
        diffDays = '0 minutes'
      }
      return {
        diffDays,
        alert
      }
    },
    getDayEx(startDate) {
      let delta = Math.abs(new Date(startDate) - Date.now()) / 1000;
      const isNegative = new Date(startDate) < Date.now() ? -1 : 1;
      let data = [
        ['days', 24 * 60 * 60],
        ['hours', 60 * 60],
        ['minutes', 60],
        ['seconds', 1]
      ].reduce((acc, [key, value]) => (acc[key] = Math.floor(delta / value) * isNegative, delta -= acc[key] * isNegative * value, acc), {});

      if(data.days < 1){
        return true
      }else{
        return false
      }
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    showSettings(id){
      this.plan = Object.assign({},id)
      this.plan.data = {
        authorized: "",
        advanced_geo: ""
      }
      setTimeout(()=>{
        this.$bvModal.show('modal-plan')
      },500)
    }
  }
}
</script>
<style>
  .plan{
    height: 23rem;
  }
</style>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

