<template>

  <b-modal
      id="modal-plan"
      ok-title="Save"
      cancel-title="Cancel"
      size="lg"
      :title="'#'+info.name"
      @ok="handleOk"
      @shown="onShown"
      no-close-on-backdrop
    >
    <b-toast id="alert_toast" :variant="toastConfig.variant" solid>
      <template #toast-title>
        <feather-icon :icon="toastConfig.icon" />
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto"> {{toastConfig.title}}!</strong>
        </div>
      </template>
      {{toastConfig.message}}
    </b-toast>
      <!-- <div  v-if="loading == false"> -->
        <b-row>
        <b-col
          lg="6"
          sm="6"
        >
          <statistic-card-horizontal
            icon="CpuIcon"
            statistic="25 Proxy Port"
            statistic-title="Port"
          />
        </b-col>
        <b-col
          lg="6"
          sm="6"
        >
          <statistic-card-horizontal
            icon="CpuIcon"
            :statistic="info.membership"
            statistic-title="Membership"
          />
        </b-col>
        <!-- <b-col
          lg="4"
          sm="4"
        >
          <statistic-card-horizontal
            icon="CpuIcon"
            statistic="86%"
            statistic-title="CPU Usage"
          />
        </b-col> -->
          </b-row>
        <b-overlay :show="loading" spinner-variant="primary"
          spinner-type="grow">

          <b-tabs>
            <b-tab active>
              <template #title>
                <feather-icon icon="UnlockIcon" />
                <span>Authorized IP's</span>
              </template>
              <br>
              <label for="textarea-default">Add Authorize IP & Select Proxy Type</label>
              <b-form-textarea
                id="textarea-default"
                placeholder="Authorized IPS. ONE PER LINE"
                rows="7"
                v-model="info.ip"
              />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="GitBranchIcon" />
                <span>Assigned Ports</span>
              </template>

              <label for="textarea-default">Assigned Ports</label>
              <b-form-textarea
                id="textarea-default"
                readonly
                placeholder="Authorized IPS. ONE PER LINE"
                rows="7"
                v-model="info.proxies"
              />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="MapPinIcon" />
                <span>Geo</span>
              </template>
              <div>
                <!-- <b-row>
                  <b-col md="8" class="">
                    <b-form-group class="ml-2" >
                      <b-form-select
                        id="geo_sl"
                        v-model="geo_sl"
                        :options="geos"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-button class="mb-1" variant="success" @click="fillGeo">
                      Fill
                    </b-button>
                  </b-col>
                </b-row> -->
                
                <br>
                <b-row>
                  <b-col md="6">
                    <h5>Default Geo</h5>
                    <b-form-select
                      v-model="info.geo"
                      :options="geos"
                    />
                  </b-col>
                  <b-col md="6">
                    <h5>Advanded Geo</h5>
                    <b-form-textarea
                      id="h-first-name"
                      v-model="info.advanded_geo"
                      rows="5"
                      max-rows="10"
                    />
                    <br>
                    <h6>Example:</h6>
                    <b-form-textarea
                      id="h-first-name"
                      placeholder="Name"
                      v-model="example"
                      rows="5"
                      max-rows="10"
                      disabled
                    />
                  </b-col>
                </b-row>
                <!-- <b-row
                  class="ml-1 mb-1"
                  v-for="(item,index) in info.geos_adv"
                  :id="item._id"
                  :key="item._id"
                >
                  <b-col md="4">
                    <b-form-input disabled :value="item.port"></b-form-input>
                  </b-col>
                  <b-col md="6">
                    <b-form-select
                      v-model="item.geo"
                      :options="geos"
                    />
                  </b-col>
                </b-row> -->
              </div>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="SetitngIcon" />
                <span>Settings</span>
              </template>
              <b-row>
                <b-col md="6" xs="6">
                  <h5> Service</h5>
                  <br>
                  <div class="mb-2">
                    Please select the services where you want the proxies to work on
                    <v-select
                      v-model="info.settings.services"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="title"
                      :options="services"
                      :selectable="() => info.settings.services.length < 6"
                    >
                    </v-select>
                    <h6 class="mb-1">Maximum 5 services*</h6>
                  </div>
                  <div class="mb-1">
                    <b-alert
                      v-height-fade.appear
                      variant="warning"
                      show
                      class="mb-0"
                    >
                      <div class="alert-body">
                        <feather-icon
                          icon="AlertTriangleIcon"
                          class="mr-50"
                        />
                        You can only make changes every two weeks, after that you have 10 minutes to make additional changes.
                      </div>
                    </b-alert>
                  </div>
                  <!-- <div class="mb-1">
                    Allow proxies to use Google Services
                    <b-form-checkbox
                      checked="true"
                      name="check-button"
                      switch
                      inline
                      v-model="info.settings.allow_gg"
                    >
                    </b-form-checkbox>
                  </div> -->
                </b-col>
                <b-col md="6" xs="6">
                  <h5> Rotation Time</h5>
                  <br>
                  <div>
                    <b-form-spinbutton
                      id="sb-inline"
                      inline
                      :min="2"
                      :max="25"
                      v-model="info.rotationTime"
                    />
                    <label
                      for="sb-inline"
                      class="mr-1"
                    >Minutes</label>
                  </div>

                  <b-form-checkbox
                    v-model="info.settings.allow_gg"
                    class="custom-control-primary mt-2"
                  >
                    Check Proxy
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
          </b-overlay>
    </b-modal>
</template>

<script>
import {
  BCard, BTabs, BRow, BCol, BTab, BFormTextarea, BButton, BAlert, BFormCheckbox, BToast, BFormSelect, BFormSpinbutton, BForm, BSpinner, BOverlay, BInputGroupAppend, BInputGroup, BFormGroup, BFormInput,VBModal , BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import Ripple from 'vue-ripple-directive'
import RepositoryFactory from '../../../api/RepositoryFactory'
const PlansRepository = RepositoryFactory.get('plans')
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const options = [
    { value: 'US' , label: 'United States' },
    { value: 'GB', label: 'United Kingdom'},
    { value: 'AU', label:'Australia'},
    { value: 'CA', label: 'Canada'},
    {value : 'FR', label: 'France'},
    {value : 'DE', label: 'Germany'},
    {value : 'AX', label: 'Åland Islands'},
    {value : 'DZ', label: 'Algeria'},
    {value : 'AS', label: 'American Samoa'},
    {value : 'AD', label: 'Andorra'},
    {value : 'AO', label: 'Angola'},
    {value : 'AI', label: 'Anguilla'},
    {value : 'AQ', label: 'Antarctica'},
    {value : 'AG', label: 'Antigua and Barbuda'},
    {value : 'AR', label: 'Argentina'},
    {value : 'AM', label: 'Armenia'},
    {value : 'AW', label: 'Aruba'},
    {value : 'AT', label: 'Austria'},
    {value : 'AZ', label: 'Azerbaijan'},
    {value : 'BS', label: 'Bahamas'},
    {value : 'BH', label: 'Bahrain'},
    {value : 'BD', label: 'Bangladesh'},
    {value : 'BB', label: 'Barbados'},
    {value : 'BY', label: 'Belarus'},
    {value : 'BE', label: 'Belgium'},
    {value : 'BZ', label: 'Belize'},
    {value : 'BJ', label: 'Benin'},
    {value : 'BM', label: 'Bermuda'},
    {value : 'BT', label: 'Bhutan'},
    {value : 'BO', label: 'Bolivia, Plurinational State of'},
    {value : 'BQ', label: 'Bonaire, Sint Eustatius and Saba'},
    {value : 'BA', label: 'Bosnia and Herzegovina'},
    {value : 'BW', label: 'Botswana'},
    {value : 'BV', label: 'Bouvet Island'},
    {value : 'BR', label: 'Brazil'},
    {value : 'IO', label: 'British Indian Ocean Territory'},
    {value : 'BG', label: 'Bulgaria'},
    {value : 'BF', label: 'Burkina Faso'},
    {value : 'BI', label: 'Burundi'},
    {value : 'KH', label: 'Cambodia'},
    {value : 'CM', label: 'Cameroon'},
    {value : 'CV', label: 'Cape Verde'},
    {value : 'KY', label: 'Cayman Islands'},
    {value : 'CF', label: 'Central African Republic'},
    {value : 'TD', label: 'Chad'},
    {value : 'CL', label: 'Chile'},
    {value : 'CX', label: 'Christmas Island'},
    {value : 'CC', label: 'Cocos (Keeling) Islands'},
    {value : 'CO', label: 'Colombia'},
    {value : 'KM', label: 'Comoros'},
    {value : 'CG', label: 'Congo'},
    {value : 'CD', label: 'Congo, the Democratic Republic of the'},
    {value : 'CK', label: 'Cook Islands'},
    {value : 'CR', label: 'Costa Rica'},
    {value : 'CI', label: 'Côte d Ivoire'},
    {value : 'HR', label: 'Croatia'},
    {value : 'CU', label: 'Cuba'},
    {value : 'CW', label: 'Curaçao'},
    {value : 'CY', label: 'Cyprus'},
    {value : 'CZ', label: 'Czech Republic'},
    {value : 'DK', label: 'Denmark'},
    {value : 'DJ', label: 'Djibouti'},
    {value : 'DM', label: 'Dominica'},
    {value : 'DO', label: 'Dominican Republic'},
    {value : 'EC', label: 'Ecuador'},
    {value : 'EG', label: 'Egypt'},
    {value : 'SV', label: 'El Salvador'},
    {value : 'GQ', label: 'Equatorial Guinea'},
    {value : 'ER', label: 'Eritrea'},
    {value : 'EE', label: 'Estonia'},
    {value : 'ET', label: 'Ethiopia'},
    {value : 'FK', label: 'Falkland Islands (Malvinas)'},
    {value : 'FO', label: 'Faroe Islands'},
    {value : 'FJ', label: 'Fiji'},
    {value : 'FI', label: 'Finland'},
    {value : 'GF', label: 'French Guiana'},
    {value : 'PF', label: 'French Polynesia'},
    {value : 'TF', label: 'French Southern Territories'},
    {value : 'GA', label: 'Gabon'},
    {value : 'GM', label: 'Gambia'},
    {value : 'GE', label: 'Georgia'},
    {value : 'GH', label: 'Ghana'},
    {value : 'GI', label: 'Gibraltar'},
    {value : 'GR', label: 'Greece'},
    {value : 'GL', label: 'Greenland'},
    {value : 'GD', label: 'Grenada'},
    {value : 'GP', label: 'Guadeloupe'},
    {value : 'GU', label: 'Guam'},
    {value : 'GT', label: 'Guatemala'},
    {value : 'GG', label: 'Guernsey'},
    {value : 'GN', label: 'Guinea'},
    {value : 'GW', label: 'Guinea-Bissau'},
    {value : 'GY', label: 'Guyana'},
    {value : 'HT', label: 'Haiti'},
    {value : 'HM', label: 'Heard Island and McDonald Islands'},
    {value : 'VA', label: 'Holy See (Vatican City State)'},
    {value : 'HN', label: 'Honduras'},
    {value : 'HK', label: 'Hong Kong'},
    {value : 'HU', label: 'Hungary'},
    {value : 'IS', label: 'Iceland'},
    {value : 'IQ', label: 'Iraq'},
    {value : 'IN', label: 'India'},
    {value : 'ID', label: 'Indonesia'},
    {value : 'IR', label: 'Iran, Islamic Republic of'},
    {value : 'IE', label: 'Ireland'},
    {value : 'IM', label: 'Isle of Man'},
    {value : 'IL', label: 'Israel'},
    {value : 'IT', label: 'Italy'},
    {value : 'JM', label: 'Jamaica'},
    {value : 'JP', label: 'Japan'},
    {value : 'JE', label: 'Jersey'},
    {value : 'JO', label: 'Jordan'},
    {value : 'KZ', label: 'Kazakhstan'},
    {value : 'KI', label: 'Kiribati'},
    {value : 'KW', label: 'Kuwait'},
    {value : 'KG', label: 'Kyrgyzstan'},
    {value : 'LA', label: 'Lao Peoples Democratic Republic'},
    {value : 'LV', label: 'Latvia'},
    {value : 'LB', label: 'Lebanon'},
    {value : 'LS', label: 'Lesotho'},
    {value : 'LR', label: 'Liberia'},
    {value : 'LY', label: 'Libya'},
    {value : 'LI', label: 'Liechtenstein'},
    {value : 'LT', label: 'Lithuania'},
    {value : 'LU', label: 'Luxembourg'},
    {value : 'MO', label: 'Macao'},
    {value : 'MK', label: 'Macedonia, the former Yugoslav Republic of'},
    {value : 'MG', label: 'Madagascar'},
    {value : 'MW', label: 'Malawi'},
    {value : 'MY', label: 'Malaysia'},
    {value : 'MV', label: 'Maldives'},
    {value : 'ML', label: 'Mali'},
    {value : 'MT', label: 'Malta'},
    {value : 'MH', label: 'Marshall Islands'},
    {value : 'MQ', label: 'Martinique'},
    {value : 'MR', label: 'Mauritania'},
    {value : 'MU', label: 'Mauritius'},
    {value : 'YT', label: 'Mayotte'},
    {value : 'MX', label: 'Mexico'},
    {value : 'FM', label: 'Micronesia, Federated States of'},
    {value : 'MD', label: 'Moldova, Republic of'},
    {value : 'MC', label: 'Monaco'},
    {value : 'MN', label: 'Mongolia'},
    {value : 'ME', label: 'Montenegro'},
    {value : 'MS', label: 'Montserrat'},
    {value : 'MA', label: 'Morocco'},
    {value : 'MZ', label: 'Mozambique'},
    {value : 'MM', label: 'Myanmar'},
    {value : 'NA', label: 'Namibia'},
    {value : 'NR', label: 'Nauru'},
    {value : 'NP', label: 'Nepal'},
    {value : 'NL', label: 'Netherlands'},
    {value : 'NC', label: 'New Caledonia'},
    {value : 'NZ', label: 'New Zealand'},
    {value : 'NI', label: 'Nicaragua'},
    {value : 'NE', label: 'Niger'},
    {value : 'NG', label: 'Nigeria'},
    {value : 'NU', label: 'Niue'},
    {value : 'NF', label: 'Norfolk Island'},
    {value : 'MP', label: 'Northern Mariana Islands'},
    {value : 'NO', label: 'Norway'},
    {value : 'OM', label: 'Oman'},
    {value : 'PW', label: 'Palau'},
    {value : 'PS', label: 'Palestinian Territory, Occupied'},
    {value : 'PA', label: 'Panama'},
    {value : 'PG', label: 'Papua New Guinea'},
    {value : 'PY', label: 'Paraguay'},
    {value : 'PE', label: 'Peru'},
    {value : 'PH', label: 'Philippines'},
    {value : 'PN', label: 'Pitcairn'},
    {value : 'PL', label: 'Poland'},
    {value : 'PT', label: 'Portugal'},
    {value : 'PR', label: 'Puerto Rico'},
    {value : 'QA', label: 'Qatar'},
    {value : 'RE', label: 'Réunion'},
    {value : 'RO', label: 'Romania'},
    {value : 'RU', label: 'Russian Federation'},
    {value : 'RW', label: 'Rwanda'},
    {value : 'BL', label: 'Saint Barthélemy'},
    {value : 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha'},
    {value : 'KN', label: 'Saint Kitts and Nevis'},
    {value : 'LC', label: 'Saint Lucia'},
    {value : 'MF', label: 'Saint Martin (French part)'},
    {value : 'PM', label: 'Saint Pierre and Miquelon'},
    {value : 'VC', label: 'Saint Vincent and the Grenadines'},
    {value : 'WS', label: 'Samoa'},
    {value : 'SM', label: 'San Marino'},
    {value : 'ST', label: 'Sao Tome and Principe'},
    {value : 'SA', label: 'Saudi Arabia'},
    {value : 'SN', label: 'Senegal'},
    {value : 'RS', label: 'Serbia'},
    {value : 'SC', label: 'Seychelles'},
    {value : 'SL', label: 'Sierra Leone'},
    {value : 'SG', label: 'Singapore'},
    {value : 'SX', label: 'Sint Maarten (Dutch part)'},
    {value : 'SK', label: 'Slovakia'},
    {value : 'SI', label: 'Slovenia'},
    {value : 'SB', label: 'Solomon Islands'},
    {value : 'SO', label: 'Somalia'},
    {value : 'ZA', label: 'South Africa'},
    {value : 'GS', label: 'South Georgia and the South Sandwich Islands'},
    {value : 'SS', label: 'South Sudan'},
    {value : 'ES', label: 'Spain'},
    {value : 'LK', label: 'Sri Lanka'},
    {value : 'SD', label: 'Sudan'},
    {value : 'SR', label: 'Suriname'},
    {value : 'SJ', label: 'Svalbard and Jan Mayen'},
    {value : 'SZ', label: 'Swaziland'},
    {value : 'SE', label: 'Sweden'},
    {value : 'CH', label: 'Switzerland'},
    {value : 'SY', label: 'Syrian Arab Republic'},
    {value : 'TW', label: 'Taiwan, Province of China'},
    {value : 'TZ', label: 'Tanzania, United Republic of'},
    {value : 'TH', label: 'Thailand'},
    {value : 'TL', label: 'Timor-Leste'},
    {value : 'TG', label: 'Togo'},
    {value : 'TK', label: 'Tokelau'},
    {value : 'TO', label: 'Tonga'},
    {value : 'TT', label: 'Trinidad and Tobago'},
    {value : 'TN', label: 'Tunisia'},
    {value : 'TR', label: 'Turkey'},
    {value : 'TC', label: 'Turks and Caicos Islands'},
    {value : 'TV', label: 'Tuvalu'},
    {value : 'UG', label: 'Uganda'},
    {value : 'UA', label: 'Ukraine'},
    {value : 'AE', label: 'United Arab Emirates'},
    {value : 'UM', label: 'United States Minor Outlying Islands'},
    {value : 'UY', label: 'Uruguay'},
    {value : 'VU', label: 'Vanuatu'},
    {value : 'VE', label: 'Venezuela, Bolivarian Republic of'},
    {value : 'VN', label: 'Viet Nam'},
    {value : 'VG', label: 'Virgin Islands, British'},
    {value : 'VI', label: 'Virgin Islands, U.S.'},
    {value : 'WF', label: 'Wallis and Futuna'},
    {value : 'EH', label: 'Western Sahara'},
    {value : 'YE', label: 'Yemen'},
    {value : 'ZM', label: 'Zambia'},
    {value : 'ZW', label: 'Zimbabwe'},
    {value : 'KR', label: 'South Korea'}
]

export default {
  components: {
    BCard,
    BRow,
    BForm,
    BCol,
    StatisticCardHorizontal,
    BTabs,
    BTab,
    BFormTextarea,
    vSelect,
    BButton,
    BAlert,
    BFormCheckbox,
    BFormSpinbutton,
    BSpinner,
    BOverlay,
    BToast,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BModal
  },
  props:{
    data: {
      type: Object,
      default: ()=> null
    }
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      selected: options[0],
      loading: true,
      geos: options.map(x=>x.value),
      geo_sl: 'US',
      curport: "",
      ports:[],
      option: options,
      example: '1000:1336:CA\n1337:1337:US\n1001:1002:GB ( UK is invalid )\n#Only 1 geo accepted',
      info: Object.assign({},this.data),
      service: [],
      services:[
        "GEO",
        "AccessGoogle",
        "Amazon",
        "Yandex",
        "Outlook",
        "AOL",
        "Zoho",
        "Gmail",
        "Apple",
        "SneakersShop",
        "Mediavine",
        "Facebook",
        "Instagram",
        "Pinterest",
        "Twitter",
        "Youtube",
        "Soundcloud",
        "Linkedin",
        "Craiglist",
        "EventTickts",
        "Myway",
        "Vimeo",
        "Vine",
        "Skype",
        "Tiktok",
        "Wechat",
        "Discord",
        "Wikipedia",
        "Imdb",
        "Yahoo",
        "Ebay",
        "Walmart",
        "Nytimes",
        "Target",
        "Bestbuy",
        "Reddit",
        "Accuweather",
        "Netflix",
        "Porn",
      ],
      toastConfig:{
        variant: 'danger',
        title: 'Test',
        icon: 'AlertOctagonIcon',
        message: 'Test message'
      }
    }
  },
  methods:{
    formatArray(s){
      return s.join('\n')
    },
    handleOk(bvModalEvt){
      bvModalEvt.preventDefault()
      //this.info.geo = this.selected[0].value
      this.loading = true
      PlansRepository.saveConfig(this.info._id,this.info).then(rs=>{
        
        if(rs.data.success){
          this.loading = false
          this.toastConfig = {
            variant: 'success',
            title: 'Config Alert',
            icon: 'AlertOctagonIcon',
            message: "Config save success"
          }
          this.$bvToast.show('alert_toast')
          this.$bvModal.hide('modal-plan')
        }else{
          // this.toastConfig = {
          //   variant: 'danger',
          //   title: 'Config Alert',
          //   icon: 'AlertOctagonIcon',
          //   message: rs.data.errMsg
          // }
          // this.$bvToast.show('alert_toast')
          this.loading = false
          this.showToast('danger','AlertCircleIcon',rs.data.errMsg)
        }
      }).catch(e=>{
        this.loading = false
        this.showToast('danger','AlertCircleIcon',e.message)
        // this.toastConfig = {
        //   variant: 'danger',
        //   title: 'Config Alert',
        //   icon: 'AlertOctagonIcon',
        //   message: e.response.data.msg
        // }
        // this.$bvToast.show('alert_toast')
        this.showToast('danger')
        

      })
    },
    onShown(){
      this.info = Object.assign({},this.data)
      this.loading = true
      PlansRepository.getConfig(this.info._id).then(rs=>{
        this.info.proxies = rs.data.data.proxies.join('\n')
        this.info.geo = rs.data.data.geo
        this.info.ip = rs.data.data.ip.join('\n')
        this.selected = options.find(x=>x.value == this.info.geo)
        this.info.rotationTime = rs.data.data.rotationTime
        // this.info.data.proxy = this.info.data.proxy.join('\n')
        this.info.geos_adv =  rs.data.data.geos_adv.filter(x=>x!="")
        this.info.advanded_geo = rs.data.data.advanded_geo.join('\n')
        //this.selected = options.filter(x=> x.value == this.info.geo)
        //this.info.settings = rs.data.data.settings
        // this.info.geos_adv.forEach(element => {
        //   element.isSelected = true
        // });
        this.ports = rs.data.data.proxies
        setTimeout(()=>{
          this.loading = false;
          
        },1000)
      })
    },
    groupByKey(array, key) {
      return array
        .reduce((hash, obj) => {
          if(obj[key] === undefined) return hash; 
          return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
        }, {})
    },

    onHide(){
    },

    fillGeo(){
      this.info.geos_adv.forEach(x=>{
        if(x.isSelected){
          x.geo = this.geo_sl
        }
      })
    },
    showToast(variant,title,icon,message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          text: message,
          variant,
        },
      })
    },
    
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

