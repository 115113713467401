<template>
  <b-card>
    <b-card-header>
      <b-card-title class="text-center"><span class="text-info">Active Account</span></b-card-title>
    </b-card-header>
    <div v-if="loading" class="d-flex justify-content-center mb-1">
      <b-spinner label="Loading..." />
    </div>
      <swiper
          ref="mySwiper"
          :options="swiperOptions"
          navigation
        >
          <swiper-slide
            v-for="data in plansData"
            :key="data._id"
          >
            <b-card
              no-body
              class="card-revenue-budget"
            >
              <b-row class="mx-0">
                <b-col
                  md="8"
                  class="revenue-report-wrapper"
                >
                  <div class="d-sm-flex justify-content-between align-items-center mb-3">
                    <h4 class="card-title mb-50 mb-sm-0">
                      {{data.name}}#{{data.membership}}
                    </h4>
                    <div class="d-flex align-items-center">
                      <b-form-checkbox
                        :checked="data.renew"
                        name="check-button"
                        switch
                        inline
                        @change="onRenew(data._id)"
                      >
                        Auto Renew
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div>
                    <b-alert
                      :show="!data.running"
                      variant="warning"
                    >
                      <div class="alert-body">
                        <feather-icon
                          class="mr-25"
                          icon="AlertTriangleIcon"
                        />
                        <span class="ml-25">You have run out of bandwidth, buy more bandwidth to continue using</span>
                        <b-badge
                          :to="{name: 'order'}"
                          variant="info"
                        >
                          <feather-icon
                            icon="LinkIcon"
                            class="mr-25"
                          />
                          <span>Buy Extra BandWidth</span>
                        </b-badge>
                      </div>
                    </b-alert>
                    <b-alert
                      show
                      :variant="diffDate(data.date_expired).diffDays > 3 ? 'success' : 'danger'"
                    >
                      <div class="alert-body">
                        <feather-icon
                          class="mr-25"
                          icon="ClockIcon"
                        />
                        <span class="ml-25">{{diffDate(data.date_expired).diffDays}} Days Expired</span>
                      </div>
                    </b-alert>
                    
                    <div class="mb-1">
                      Status: <b-badge :variant="data.running ? 'success' :'danger'">
                        <feather-icon
                          icon="BarChart2Icon"
                          class="mr-25"
                        />
                        <span v-if="data.running">Running</span>
                        <span v-else>Suspend</span>
                      </b-badge>
                      <b-button
                        v-if="!data.running"
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-info"
                        class="btn-icon"
                        @click="reactive(data._id)"
                        >
                        <feather-icon
                            icon="PowerIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Reactive</span>
                      </b-button>
                    </div>
                    <div class="mb-1">
                      Number Port: <b-badge variant="success">
                        <feather-icon
                          icon="Share2Icon"
                          class="mr-25"
                        />
                        <span>{{data.proxies.length}}</span>
                      </b-badge>
                    </div>
                    <div class="mb-1">
                      Rotate: <b-badge variant="success">
                        <feather-icon
                          icon="RssIcon"
                          class="mr-25"
                        />
                        <span>{{data.rotationTime}} minutes</span>
                      </b-badge>
                    </div>
                    <div class="mb-1">
                      Membership: <b-badge variant="success"> 
                        <feather-icon
                          icon="KeyIcon"
                          class="mr-25"
                        />
                          {{data.membership}}
                        </b-badge>
                        <b-button
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            variant="flat-success"
                            class="btn-icon"
                            v-clipboard:copy="data.membership"
                            v-clipboard:success="onCopy"
                            v-clipboard:error="onError"
                            >
                            <feather-icon
                                icon="CopyIcon"
                                class="mr-50"
                            />
                        </b-button>
                    </div>
                    <hr>
                    <div v-if="data.show">
                      <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-info"
                        @click="showSettings(data)"
                      >
                        <feather-icon
                          icon="SettingsIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Settings</span>
                      </b-button>
                      <!-- <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-success"
                        @click="renewAccount(data._id)"
                      >
                        <feather-icon
                          icon="FileTextIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Renewal</span>
                      </b-button> -->
                      <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-warning"
                        :to="{name: 'billing'}"
                      >
                        <feather-icon
                          icon="CreditCardIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Billing</span>
                      </b-button>
                    </div>
                    <div v-else>
                      <b-alert variant="warning" show>Please wait for settings</b-alert>
                    </div>
                  </div>
                </b-col>
                <b-col
                  md="4"
                  class="budget-wrapper"
                >
                  <h6 class="card-title mb-50 mb-sm-0">
                      CAPACITY STATS
                    </h6>
                  <vue-apex-charts
                    height="200"
                    :options="data.chartOptions" :series="data.series"
                  />
                <br>
                <h6 class="text-center">{{formatBytes(data.bandwidth.total - data.bandwidth.used)}} remaining.</h6>
                <b-row class="text-center mx-0">
                    <b-col
                      cols="6"
                      class="border-top border-right d-flex align-items-between flex-column py-1"
                    >
                      <b-card-text class="text-muted mb-0">
                        Total
                      </b-card-text>
                      <h5 class="font-weight-bolder mb-50">
                        {{formatBytes(data.bandwidth.total)}}
                      </h5>
                    </b-col>

                    <b-col
                      cols="6"
                      class="border-top d-flex align-items-between flex-column py-1"
                    >
                      <b-card-text class="text-muted mb-0">
                          Consumed
                      </b-card-text>
                      <h5 class="font-weight-bolder mb-0">
                        {{formatBytes(data.bandwidth.used)}}
                      </h5>
                    </b-col>
                  </b-row>
                <br>
              </b-col>
            </b-row>
          </b-card>
        </swiper-slide>
        <div
          slot="pagination"
          class="swiper-pagination"
        />
        </swiper>
      <plan-modal v-if="plan" :data="plan" ref="modal"></plan-modal>
    </b-card>

</template>

<script>
import {
 BRow,BCol, BCard, BCardHeader, BCardTitle, BBadge, BButton,BCardFooter,BCardText, BCardBody, BSpinner, BFormCheckbox, BAlert, BModal, VBModal
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Swiper, SwiperSlide , } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RepositoryFactory from '../../../api/RepositoryFactory'
const PlansRepository = RepositoryFactory.get('plans')

import PlanModal from '../modal/PlanModal.vue'

import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'


export default {
  components: {
    BRow,BCol, BCard,BCardHeader,BCardTitle,BBadge,BButton,BCardFooter,BCardText,BCardBody,
    Swiper,
    SwiperSlide,
    BSpinner,
    'plan-modal':PlanModal,
    VueApexCharts,
    BFormCheckbox,
    BAlert,
    BModal
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      plan: null,
      plansData:[],
      loading: false,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
        },
      },
      series: [50],
      chartOptions: {
        chart: {
          height: 600,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '70%',
            }
          },
        },
        labels: ['Used'],
      },
    }
  },
  beforeCreate(){
  },
  created(){
    this.loading = true
    PlansRepository.fetch().then(rs=>{
      this.loading = false
      this.plansData = rs.data.data
      this.plansData.forEach(x=>{
        x.chartOptions = {
          chart: {
              height: 350,
              type: 'radialBar',
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  size: '70%',
                }
              },
            },
            labels: ['Used'],
        }
        let used_percent = (x.bandwidth.used/x.bandwidth.total*100).toFixed(2)
        x.series = [used_percent]
      })
      //let count = rs.data.plans.filter
      this.$parent.updatePlan(this.plansData.length)
      let account_ex = this.plansData.filter(x=>this.diffDate(x.date_expired).diffDays < 3)
      this.$parent.updatePlanEx(account_ex.length)
      this.$parent.updateRenew(this.plansData)
    })
  },
  methods:{
    cancelSubs(id,url){
      Paddle.Checkout.open({
        override: url,
        successCallback: function(data){
          this.plansData.find(x=>x._id==id).cancel = true
          this.$swal({
            title: 'Success',
            text: 'Cancel sucess',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }.bind(this))
        }
      });
    },
    diffDate(d){
      var diffDays = parseInt((new Date(d) - Date.now()) / (1000 * 60 * 60 * 24), 10); 
      let alert = "";
      if(diffDays > 3)
        alert = "light-success"
      else
        alert = "light-danger"

      if(diffDays <= 0)
        diffDays = 0
      return {
        diffDays,
        alert
      }
    },
    
    showSettings(id){
      this.plan = Object.assign({},id)
      setTimeout(()=>{
        this.$bvModal.show('modal-plan')
      },500)
    },
    onRenew(id){
      PlansRepository.setAuto(id).then(rs=>{
        this.plansData.find(x=>x._id == id).renew = rs.data.value
      })
      
    },
    reactive(id){
      PlansRepository.reactive(id).then(rs=>{
        if(rs.data.success){
          this.plansData.find(x=>x._id == id).running = true
          this.$swal({
                icon: 'success',
                title: 'Success',
                text: 'Reactive Success',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
        }else{
          this.$swal({
                icon: 'error',
                title: 'Error',
                text: rs.data.errMsg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
        }
      })
    },
    renewAccount(id){
      let acc = this.plansData.find(x=>x._id == id)
      let tit = ""
      if(acc.sltype == 10)
        tit = "You won't be able to revert this!, You will get 30 day for 175$"
      else
        tit = "You won't be able to revert this!, You will get 30 day for 350$"
      this.$swal({
        title: 'Are you sure?',
        text: tit,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Renew',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          PlansRepository.renew(id).then(rs=>{
            if(rs.data.success){
              this.plansData.find(x=>x._id == id).date_expired = rs.data.date
              this.$swal({
                icon: 'success',
                title: 'Success',
                text: 'Renewal Success',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }else{
              this.$swal({
                icon: 'error',
                title: 'Error',
                text: rs.data.errMsg,
                customClass: {
                  confirmButton: 'btn btn-error',
                },
              })
            }
          })
        }
      })
    },
    formatBytes(bytes) {
      var marker = 1000; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB
      var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
      // return bytes if less than a KB
      if(bytes < kiloBytes) return bytes + " Bytes";
      // return KB if less than a MB
      else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
      // return MB if less than a GB  ``
      else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
      // return GB if less than a TB
      else if(bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal) + " GB";
      
      else return (bytes / teraBytes).toFixed(decimal) + " TB";
    },
    onCopy: function (e) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Text copied',
                    icon: 'BellIcon',
                },
            })
        },
        onError: function (e) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Can not copy!',
                    icon: 'BellIcon',
                },
            })
        },
  }
}
</script>
<style>
  .plan{
    height: 23rem;
  }
</style>

